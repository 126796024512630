import { Cookies } from "react-cookie"

class RedditClient {
  constructor(clientID, clientSecret, redirectURI) {
    this.clientID = clientID
    this.clientSecret = clientSecret
    this.redirectURI = redirectURI
    this.code = null

    
    this.access_token = new Cookies().get("access_token")
    // this.access_token = null
    this.RANDOM_STRING = Math.random()
      .toString(36)
      .substring(7)
  }

  authenticate = async () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    if (urlParams.get("code")) {
      console.log("Getting code")
      this.code = urlParams.get("code")
      
      await this.getCode().then(res=>{
        console.log(res)
        let cookie = new Cookies()
        cookie.set("access_token", res, { path: '/', maxAge: 3600 })
        window.location.href=this.redirectURI
      })
      
      // return this.access_token
    } else {
      this.redirect()
    }
  }

  getCode = async () => {
    const encode = window.btoa(`${this.clientID}:${this.clientSecret}`)

    console.log("Getting normal token")
    
    return fetch("https://www.reddit.com/api/v1/access_token", {
      method: "post",
      headers: new Headers({
        Authorization: "Basic " + encode,
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      body: `grant_type=authorization_code&code=${this.code}&redirect_uri=${this.redirectURI}`,
    }).then(res=> res.json()).then(json=> {return json.access_token})
    // const json = await res.json()
    // console.log(json.access_token)
    // return json.access_token
  }

  checkCode = () => {
    return !(this.code == null)
  }

  redirect = () => {
      window.location.href = `https://www.reddit.com/api/v1/authorize?client_id=${this.clientID}&duration=temporary&redirect_uri=${this.redirectURI}&response_type=code&scope=identity+edit+read+mysubreddits+history&state=${this.RANDOM_STRING}`
  }

  getUserSubscriptions = async () => {

    const res = await this.fetchEndpoint("https://oauth.reddit.com/subreddits/mine/subscriber?limit=100")
    // const res = await this.fetchEndpoint("https://oauth.reddit.com/api/multi/mine")
    // console.log(res)
    return await res.data.children
  }


  getSubmissions = async (subreddit,sortType,time) => {
    const res = await this.fetchEndpoint(`https://oauth.reddit.com/r/${subreddit}/${sortType}?t=${time}`)
    return await res.data.children
  }



  fetchEndpoint = async (endpoint) => {
    let access_token = new Cookies().get("access_token")
    const res = await fetch(
      endpoint,
      {
        headers: new Headers({
          Authorization: "Bearer " + access_token,
        }),
      }
    )
    
    const json = await res.json(res => {return res})
    
    return await json
  }

}

export default RedditClient
