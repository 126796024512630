import { useState, useEffect } from "react"
import RedditClient from "../utils/RedditClient"
import { Cookies } from "react-cookie"


// const Client = new RedditClient()

export function useRedditClient() {
  const REDIRECT_URI = `${process.env.REDIRECT_URI}`
  // const REDIRECT_URI = "https://app.embridea.com/"
  const CLIENT_ID = `${process.env.CLIENT_ID}`
  // const CLIENT_ID = "BnEVt0xoYdMkaw"
  
  const [client, setClient] = useState(null)

  useEffect(() => {
    if (!client) {
      const Client = new RedditClient(CLIENT_ID, "", REDIRECT_URI)
      setClient(Client)
    }
  })

  return client
}


export function useIsLoggedIn() {

  const [isloggedin, setIsloggedin] = useState(false)

  const isLoggedIn = () => {
    const access_token = new Cookies().get("access_token")
    if (!access_token){
      setIsloggedin(false)
    }else{
      setIsloggedin(true)
    }
  }

    useEffect(() => {
      isLoggedIn()
    }, [isloggedin])
  

  return isloggedin
}
